@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: theme(colors.green.800);
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: theme(colors.red.800);
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: theme(colors.green.800);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: theme(colors.red.800);

    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 70px;
    --toastify-toast-max-height: 800px;
    --toastify-z-index: 9999;
    --toastify-font-family: theme(fontFamily.dm);

    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;

    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;
    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;
  }
  .Toastify__toast-icon {
    @apply [&_svg]:!w-7 !important;
  }
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}

.pagination-item-style:hover {
  background-color: #f1c40f;
  color: white;
  border: 1px solid #f1c40f;
}
.pagination-item-style {
  display: flex;
  justify-content: center;
  justify-items: center;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;

  /* @apply border-primary-100 text-black hover:bg-primary-100  flex cursor-pointer items-center justify-center rounded-md border hover:text-white; */
}
.disabled-item {
  /* @apply hover:bg-primary-100 opacity-50 hover:text-white; */
}
.pagination-item-style a,
.disabled-item a {
  /* @apply flex-center h-full w-full px-4 py-1; */
  height: 100%;
  width: 100%;
  padding: 5px 10px;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 100% !important;
  min-width: 400px !important;
}

.css-uhb5lp {
  max-width: 100% !important;
  min-width: 400px !important;
}

.MuiPaper-root,
.MuiPaper-elevation,
.MuiPaper-rounded,
.MuiPaper-elevation24,
.MuiDialog-paper,
.MuiDialog-paperScrollPaper,
.MuiDialog-paperWidthSm,
.css-uhb5lp {
  max-width: 100% !important;
  min-width: 400px !important;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #728fea;
  width: 7px;
  height: 7px;
  border-radius: 5px;
}
/* custom-datepicker.css */
.date-picker-container {
  position: relative;
  width: 160px;
}

/* .custom-date-picker {
  Custom styles for date picker
} */

.custom-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 4px 4px 0;
  background-color: #007bff;
  color: white;
}
.react-datepicker__input-container input {
  /* Add your custom styles here */
  width: 100% !important;
  padding: 8px;
  border: 1px solid;
  border: 1px solid #d0dcfb;
  border-radius: 8px;
  outline: none;
  border-color: #d0dcfb;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker__input-container input[type="text"] {
  background-color: var(
    --bg-color-light
  ); /* Default to light mode background color */
}

/* For dark mode, change the background color */
body.dark-mode .react-datepicker__input-container input[type="text"] {
  background-color: var(--bg-color-dark);
  border-color: #4765e0;
}
.ay-center {
  @apply absolute top-1/2 -translate-y-1/2;
}
.react-date-picker__wrapper {
  display: flex !important;
  justify-content: space-between !important;
  border: 1px solid #d0dcfb !important;
  height: 40px !important;
  margin-top: 8px !important;
  border-radius: 8px;
  color: black !important;
  outline: none !important;
}
.react-date-picker__inputGroup__input react-date-picker__inputGroup__year {
  outline: none !important ;
}

.react-date-picker__clear-button__icon {
  display: none !important;
}

.react-date-picker__inputGroup__input {
  outline: none !important;
}
